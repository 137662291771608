<template>
  <div class="living clearfix">
    <div class="liveLeft fl">
      <div style="height: 100%">
        <AliyunVideo
          v-if="livingList.length === 0 || isAliyun"
          :AliyunId="currentPlayId"
        ></AliyunVideo>
        <el-image
          v-if="livingList.length > 0 && !isAliyun"
          class="img"
          :class="{ cursor: nowLive && nowLive.watch_url }"
          :src="nowLive.live_img"
          @click="handleClick(nowLive)"
        ></el-image>
      </div>
      <div class="liveStatus clearfix">
        <div class="left fl">
          <div v-if="livingList.length > 0">
            <div class="clearfix nostart" v-show="nowLive.isTimer == 1">
              <div class="line fl"></div>
              <div class="fl">{{ $t("haveNotStarte") }}</div>
            </div>
            <div class="clearfix end" v-show="nowLive.isTimer == 3">
              <div class="line fl"></div>
              <div class="fl">{{ $t("meetingReturnLive") }}</div>
            </div>
            <div class="clearfix underway" v-show="nowLive.isTimer == 2">
              <div class="line fl"></div>
              <div class="fl">{{ $t("meetingBegining") }}</div>
            </div>
          </div>
        </div>
        <!-- <div>{{livingList}}</div> -->
        <div
          class="right fl textOverflow"
          v-if="nowLive.issue_zh || nowLive.issue_en"
        >
          {{
            nowLive.issue_en
              | priorFormat(nowLive.issue_zh, LOCALE)
              | lineFormat
          }}
        </div>
        <!--        <div class="right fl textOverflow" v-else>-->
        <!--          {{ $t("schoolMeetng") }}-->
        <!--        </div>-->
      </div>
    </div>
    <div class="liveRight fr">
      <advertising1></advertising1>
      <!-- <div>
        <div class="dateTitle">
          <div v-if="livingList.length > 0">
            {{ livingList.length > 0 && livingList[0].date }}
          </div>
          <div v-else>{{ dateTitle }}</div>
        </div>
        <div class="todayData">
          <div v-if="livingList.length > 0">
            <div
              class="todayItem"
              v-for="(it, index) in livingList[0].dataList"
              :key="index"
            >
              <div class="clearfix">
                <div class="fl">
                  {{ it.start_time | secondFormat("T") }} -
                  {{ it.end_time | secondFormat("T") }}
                </div>
             
              </div>
              <div class="clearfix">
                <div v-if="it.agenda_type == 1" class="livedet">
                  <div class="fl type">{{ $t("meetingZhibo") }}</div>
                  <div
                    class="fl text textOverflow issueHover"
                    @click="issueHoverDate(it)"
                    :title="it.issue_en | priorFormat(it.issue_zh, LOCALE)"
                  >
                    {{ it.issue_en | priorFormat(it.issue_zh, LOCALE) }}
                  </div>
                </div>
                <div v-if="it.agenda_type == 2" class="meetdet">
                  <div class="txtOverflow fl" style="line-height: 20px">
                    {{ $t("roundLive") }}：
                  </div>
                  <div
                    class="textOverflow text1 fl issueHover"
                    @click="issueHoverDate(it)"
                    :title="it.issue_en | priorFormat(it.issue_zh, LOCALE)"
                  >
                    {{ it.issue_en | priorFormat(it.issue_zh, LOCALE) }}
                  </div>
                </div>
                <div class="fr status clearfix">
                  <div class="fl div">
              
                    <div
                      v-show="it.isTimer != 3"
                      class="underway"
                      :class="{
                        cursor: it.watch_url,
                      }"
                      @click="handleClick(it)"
                    >
                      {{ $t("meetingEnter") }}
                    </div>
                    <div
                      v-show="it.isTimer == 3"
                      class="end"
                      :class="{
                        cursor: it.watch_url,
                      }"
                      @click="handleClick(it)"
                    >
                      {{ $t("meetingReturnLive") }}
                    </div>
                  </div>
                  <div class="fr">
                    <el-image
                      :src="idcodeImg"
                      style="width: 20px; margin-left: 5px"
                      class="cursor"
                      @click="isIdCodeDialog(it.watch_url)"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <noDataImg v-if="livingList.length == 0"></noDataImg>
        </div>
      </div>
      <div
        class="cursor dateIcon"
        @click="dialogVisible = true"
        v-show="!dataImg"
      >
        <el-image
          class="img"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/images/timeData.png"
        ></el-image>
      </div> -->
    </div>
    <!-- 日历选择 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="325px"
      class="selectTime"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
    >
      <datePicker
        @dateSelect="getdate"
        :start="startTime"
        :end="endTime"
        :default="defaultTime"
      ></datePicker>
    </el-dialog>
    <idCode ref="idCode" :isCustom="true" :customUrl="customUrl"></idCode>
  </div>
</template>
<script>
/**
 * 传给父组件的方法：
 * 1.dateChange(item)点击日历切换日期发射的方法（此方法主要功能就是刷新数据，把当前页面数据改成所选日期的数据）
 * 2.toLivePath(item)点击进入直播的方法
 *
 *
 * 插槽：slot：status（右边按钮）
 */
import datePicker from "~/baseComponents/datePicker";
import idCode from "~/baseComponents/idCode";
import { secondFormat, lineFormat } from "~/basePlugins/filters";
import AliyunVideo from "@/baseComponents/AliyunVideo";
import advertising1  from "../advertising1.vue"
export default {
  components: {
    datePicker,
    idCode,
    AliyunVideo,
    advertising1
  },

  props: {
    livingList: {
      //数组要经过formatDate处理过的
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      idcodeImg: require("~scr/assets/img/idcode1.png"),
      defaultLiveImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/positionBanner.png",
      nowLive: {},
      dialogVisible: false,
      idcodeVisible: false,
      dateTitle: "",
      liveLoading: false,
      startTime: 1606752000,
      endTime: 1609430399,
      defaultTime: 1606752000,
      isAliyun: false,
      customUrl: null,
      dataImg: false,
      currentPlayId: "c0322ddf651371ef95d44531949c0102",
      // currentPlayId:"9cdf4ff44eae4b929f845737d508c6f3"
    };
  },
  watch: {
    // livingList() {
    //   this.liveWatchImg();
    // },
  },
  mounted() {
    // this.startTime = this.$store.state.meeting_info.start_time;
    // this.endTime = this.$store.state.meeting_info.end_time;
    // if (this.MEETING_STATUS == 2) {
    //   this.dateTitle = moment(new Date()).format("YYYY-MM-DD");
    // } else {
    //   this.dateTitle = moment(this.startTime * 1000).format("YYYY-MM-DD");
    // }
    // let time = this.endTime - this.startTime;
    // if (time < 86400) {
    //   this.dataImg = true;
    // }
  },
  methods: {
    // 左侧封面图
    liveWatchImg(obj) {
      if (this.livingList.length == 0) {
        this.nowLive = {};
        return false;
      }
      this.isAliyun = false;
      let arr = this.livingList[0].dataList;
      let todayStart = moment().startOf("day");
      let todayEnd = moment().endOf("day");
      let nowTime = moment(new Date()).unix();
      let arrFil = [];
      if (obj) {
        this.nowLive = obj;
      } else if (this.MEETING_STATUS == 2) {
        this.nowLive = arr[0];
      } else {
        if (this.livingList.length > 0 && arr.length > 0) {
          let objNow = arr.find((it) => {
            return it.start_time <= nowTime && nowTime < it.end_time;
          });
          let objNext = arr.find((it) => {
            return it.start_time > nowTime;
          });
         
          if (objNow) {
            this.nowLive = objNow;
          } else {
            this.isAliyun = true;
            this.nowLive = {};
          }
        } else {
          this.nowLive = {};
        }
      }
    },
    handleClick(item) {
      // if (item.isTimer == 1 && !item.is_subscribe) {
      //   this.$message.warning("请先预约");
      // } else {
      // }
      this.$emit("toLivePath", item);
    },
    issueHoverDate(it) {
      // this.nowLive = it;
      this.liveWatchImg(it);
    },
    // 二维码
    isIdCodeDialog(url) {
      this.customUrl = url;
      this.$refs.idCode.isIdCodeDialog();
    },
    loadingShow(val) {
      this.liveLoading = val;
    },
    // 切换日期
    getdate(result) {
      this.dialogVisible = false;
      let obj = {};
      obj.start_time = result.startTime;
      obj.end_time = result.endTime;
      this.$emit("dateChange", obj);
      this.liveWatchImg();
      this.dateTitle = moment(result.time * 1000).format("YYYY-MM-DD");
    },
    // 预约
    async remind(item, index) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      } else {
        if (item.is_subscribe == 1) {
          this.$message({
            message: "您已成功预约",
            type: "warning",
            offset: 100,
          });
          return false;
        }
        let params = {};
        params.agenda_type = item.live_type;
        params.user_id = this.USER_ID;
        params.meeting_id = this.MEETING_ID;
        params.remind_time = item.start_time;
        params.agenda_id = item.id;
        try {
          let res = await this.$store.dispatch(
            "API_booth/reservationMeetingBoothAgenda",
            params
          );
          if (res.success) {
            if (res.remind_id) {
              this.$message({
                message: "预约成功",
                type: "success",
                offset: 100,
              });
              this.livingList[0].dataList[index].is_subscribe = 1;
            } else {
              this.$message({
                message: "预约失败",
                type: "error",
                offset: 100,
              });
            }
          }
        } catch (error) {}
      }
    },
  },
};
</script>
<style lang="less" scoped>
.living {
  min-height: 300px;
  margin-bottom: 30px;
  .liveLeft {
    width: 976px;
    height: 563px;
    position: relative;
    .img {
      height: 563px;
      width: 100%;
    }
    .liveStatus {
      position: absolute;
      top: 20px;
      left: 20px;
      padding: 2px 10px;
      .left {
        border-radius: 3px;
        line-height: 24px;
        div {
          color: #fff;
        }
        .nostart {
          padding: 2px 10px;
          background: linear-gradient(90deg, #6fbcff 0%, #3283f6 100%);
        }
        .underway {
          padding: 2px 10px;
          background: linear-gradient(270deg, #fa2d64 0%, #f41434 100%);
        }
        .end {
          padding: 2px 10px;
          background: linear-gradient(270deg, #e6a52c 0%, #fad55e 100%);
        }
      }
      .right {
        line-height: 24px;
        padding: 2px 10px;
        background: rgba(0, 0, 0, 0.62);
        color: #fff;
        max-width: 800px;
      }
      .line {
        width: 6px;
        height: 6px;
        background: #fefefe;
        border-radius: 3px;
        margin: 10px 5px 0 0;
      }
    }
  }

  
  .liveRight {
    width: 300px;
    height: 563px;
    background: #fff;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    .dateTitle {
      font-size: 14px;
      padding: 0 16px;
    }
    .todayData {
      overflow: auto;
      height: 500px;
      padding: 0 16px;
    }
    .todayItem {
      height: 70px;
      border-bottom: 1px solid #e7e7e7;
      padding: 12px 0;
      .livedet,
      .meetdet {
        margin-top: 8px;
        .type {
          padding: 0 5px;
          height: 20px;
          background: #26b7ff;
          border-radius: 3px;
          line-height: 20px;
        }
        .text {
          max-width: 50%;
          line-height: 20px;
          margin: 0 8px;
        }
        .text1 {
          max-width: 45%;
          line-height: 20px;
          margin: 0 8px;
        }
      }
      .status {
        .div div {
          padding: 0 8px;
          line-height: 20px;
          border-radius: 3px;
        }
        .nostart {
          background: linear-gradient(90deg, #6fbcff 0%, #3283f6 100%);
          color: #fff;
        }
        .underway {
          background: linear-gradient(270deg, #fc6767 0%, #ec008c 100%);
          color: #fff;
        }
        .end {
          color: #fad55e;
          border: 1px solid #fad55e;
        }
      }
    }
  }
  .issueHover {
    &:hover {
      color: #26b7ff;
      cursor: pointer;
    }
  }
  .dateIcon {
    position: absolute;
    top: 15px;
    right: 20px;
    .img {
      width: 25px;
      height: 25px;
    }
  }
}
.selectTime {
  /deep/.el-dialog__body {
    padding: 0;
    width: 320px;
  }
}
/deep/.el-picker-panel {
  margin: 0;
}
/deep/.el-dialog__headerbtn {
  top: 10px;
}
</style>
