<template>
  <div class="jobCanAppointmentBox">
    <div class="interviewerMsg">
      <div class="interviewerMsgFl">
        <img
          :src="item.avatar ? item.avatar : defaultAvatar"
          alt=""
          srcset=""
        />
        <div class="msg">
          <p class="name">
            {{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}
          </p>
          <p class="titie">
            {{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }} |
            <span class="status online" v-if="item.status == 1">在线</span>
            <span class="status" v-if="item.status == 2" style="color: #e6a23c"
              >忙碌</span
            >
            <span
              v-if="item.status == 3"
              style="color: #aaa"
              class="status online"
              >离线</span
            >
          </p>
        </div>
      </div>
      <div class="interviewerMsgFr">
       <immediatelyTalk :channel_id="item.live_id" :status="item.status" :userId="item.user_id" :oToId="item.one2one_id"></immediatelyTalk>
      </div>
    </div>
    <p class="address">
      工作地点：<template v-if="item.job_position"
        ><span v-for="(ii, index) in item.job_position" :key="index"
          >{{ ii }} <span v-if="index != item.job_position.length - 1">/</span>
        </span>
      </template>
    </p>
    <div class="compamyMsg">
      <el-image
        class="logo"
        :src="
          item.logo
            ? item.logo
            : 'https://vod.aila.site/image/default/FE170CC66F2540498E6006698E866349-6-2.png'
        "
        fit="contain"
      ></el-image>

      <div class="otherMsg">
        <p class="name" style="cursor:pointer" @click="gotoCompany(item.company_id)">
          {{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}
        </p>
        <p class="industry">{{ item.industry | industryFormat('zh') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import immediatelyTalk from "~scr/components/button/immediatelyTalk"
import { industryFormat } from "~/basePlugins/filters";
import { Industry } from "~/baseUtils/enumValue"; //根据国家ID匹配国家名称
export default {
  props: ["item"],
  mounted() {
    // console.log(industryFormat(this.item.industry,this.LOCALE))
  },
  components:{immediatelyTalk},
  methods: {
    gotoCompany(id) {
      this.$router.push({
        path: "/companyList/booth",
        query: {
          parameter: this._encode({
            company_id: id,
          }),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.jobCanAppointmentBox {
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 15px;
  width: 424px;
  height: 184px;
  .interviewerMsg {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .interviewerMsgFl {
      display: flex;
      align-items: center;
      img {
        width: 57px;
        height: 57px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .msg {
        width: 240px;
        .name {
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-bottom: 10px;
          font-size: 16px;
        }
        .titie {
          color: #999;
          font-size: 14px;
          .online {
            color: #12bf48;
          }
        }
      }
    }
  }
  .address {
    font-size: 14px;
    color: #666;
    padding: 10px 0;
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .compamyMsg {
    margin-top: 12px;
    display: flex;
    align-items: center;
    .logo {
      width: 40px;
      height: 40px;
      border: 1px solid #e9e9e9;
    }
    .otherMsg {
      width: 330px;
      margin-left: 15px;
      .name {
        padding-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
      }
      .industry {
        color: #999999;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>