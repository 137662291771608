<template>
  <div class="recommendJob">
    <div class="titleMsg">
      <div class="topMSg">
        <div class="title" style="cursor:pointer" @click="gotoTitleDetail(item.id)">{{ item.name }}</div>
        <div class="money">
          {{ item.salary_lowest }}K-{{ item.salary_highest==21?`20K以上`:`${item.salary_highest}K` }}
        </div>
      </div>
      <div class="botMsg">
        <span
          >{{ item.city_name_zh | textFormat }} |
          {{ item.experience | experienceFormat(LOCALE) }} |
          {{ item.level_require | levelRequireFormat(LOCALE) }}</span
        >
      </div>
    </div>
    <div class="compamyMsg">
      <el-image
        class="logo"
        :src="
          item.logo
            ? item.logo
            : 'https://vod.aila.site/image/default/FE170CC66F2540498E6006698E866349-6-2.png'
        "
        fit="contain"
      ></el-image>

      <div class="otherMsg">
        <p
          class="name"
          style="cursor: pointer"
          @click="gotoCompany(item.company_id)"
        >
          {{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}
        </p>
        <p class="industry">{{ item.industry | industryFormat('zh') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
     gotoTitleDetail(id) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: id,
          }),
        },
      });
    },
    gotoCompany(id) {
      this.$router.push({
        path: "/companyList/booth",
        query: {
          parameter: this._encode({
            company_id: id,
          }),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.recommendJob {
  width: 424px;
  height: 201px;
  background: #fff;
  border: 1px solid #e9e9e9;
  padding: 0 16px;
  .titleMsg {
    padding: 30px 0;
    border-bottom: 1px solid #e9e9e9;
    .topMSg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 16px;
      }
      .money {
        color: #ff2b0d;
      }
    }
    .botMsg {
      font-size: 14px;
      color: #999;
      padding-top: 5px;
    }
  }
  .compamyMsg {
    margin-top: 12px;
    display: flex;
    align-items: center;
    .logo {
      width: 70px;
      height: 70px;
      border: 1px solid #e9e9e9;
    }
    .otherMsg {
      width: 280px;
      margin-left: 15px;
      .name {
        padding-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
      }
      .industry {
        color: #999999;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>