<template>
  <div class="">
    <div class="liveBox content_area">
      <negotiate :livingList="livingList"></negotiate>
    </div>
    <div class="content_area dataAll">
      <div class="dataList">
        <div class="dataItem">
          <div class="title">在线招聘企业</div>
          <div class="number">{{ companyNo }}</div>
        </div>
        <div class="dataItem">
          <div class="title">招聘职位</div>
          <div class="number">{{ jobNo }}</div>
        </div>
        <div class="dataItem">
          <div class="title">参与人数</div>
          <div class="number">{{ personNo }}</div>
        </div>
      </div>

      <div class="searchBox">
        <el-input placeholder="请输入职位" v-model="keyWord" class="input-with-select">
          <!-- <CityComplete v-model="select" slot="prepend"></CityComplete> -->
          <el-select
            v-model="select"
            filterable
            slot="prepend"
            placeholder="期望工作地点"
          >
            <el-option
              v-for="(item, index) in cityList"
              :label="item.name_zh"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSeachClick(false)"
          ></el-button>
        </el-input>
      </div>
      <div class="keyWord">
        <div class="keyWordTitle">热搜：</div>
        <div class="keyWordList">
          <span
            class="keyWordItem"
            v-for="(item, index) in hotSearchList"
            :key="index"
            @click="handleSeachClick(item)"
            >{{ item }}</span
          >
        </div>
      </div>
    </div>
    <!-- <div class="bannerAd content_area">
      <el-image
        class="images"
        src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
      >
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div> -->
    <!-- <div class="recruitHall content_area">
      <div class="moduleTitle">
        <p class="titleTxt">招聘大厅</p>
        <p class="titleDesc">自由洽谈，HR在线1v1</p>
      </div>
      <div class="searchContent">
        <div class="keyWordSearch">

        </div>
        <div class="searchInput">
          <el-input
            placeholder="请输入负责人名、公司名称"
            v-model="searchJob"
            class="input-with-select"
          >
            <el-button slot="append" icon="el-icon-search" @click="searchInterviewer"
              >搜索</el-button
            >
          </el-input>
        </div>
      </div>
      <div class="jobList">
        <div
          style="width: 100%; height: 300px; position: relative"
          v-if="!interviewerList.length"
        >
          <noDataImg></noDataImg>
        </div>
        <jobCanAppointmentBox
          class="jobItem"
          v-for="(item, index) in interviewerList"
          :item="item"
          :key="index"
        ></jobCanAppointmentBox>
      </div>
      <div class="pages clearfix" style="margin: 15px 0">
        <el-pagination
          class="fr"
          background
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="start"
          :page-size="limit"
          @current-change="interviewerListPageChage"
        >
        </el-pagination>
      </div>
    </div> -->
    <!-- <div class="InvitationBox" v-if="show">
      <div class="content_area">
        <div class="moduleTitle">
          <p class="titleTxt">面试预约</p>
          <p class="titleDesc"></p>
        </div>

        <div class="Invitation">
          <div class="timeBox">
            <div class="nowTime">{{ interviewDate }}</div>
            <div class="timeIcon" @click="dialogVisible = true">
              <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/images/timeData.png"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div class="InvitationList" v-loading="Invitatloading">
            <div
              style="width: 100%; height: 500px; position: relative"
              v-if="!interviewList.length"
            >
              <noDataImg></noDataImg>
            </div>
            <div
              class="InvitationItem"
              v-for="(item, index) in interviewList"
              :key="index"
            >
              <div class="jobTime">
                {{ item.start_time | secondFormat("T") }}-{{
                  item.end_time | secondFormat("T")
                }}
              </div>
              <div class="threeBox">
                <p
                  class="title"
                  style="cursor: pointer"
                  @click="gotoTitleDetail(item.id)"
                >
                  {{ item.job_name | textFormat }}
                </p>
                <p class="money">{{ item.salary_lowest }}-{{ item.salary_highest }}K</p>
                <p class="mast" v-if="item.state == 1" style="color: #aaa">未开始</p>
                <p class="mast" v-if="item.state == 2" style="color: #e6a23c">即将开始</p>
                <p class="mast" v-if="item.state == 3" style="color: #67c23a">进行中</p>
                <p class="mast" v-if="item.state == 4" style="color: #aaa">已结束</p>
              </div>
              <div class="compamyMsg">
                <el-image
                  class="logo"
                  :src="
                    item.logo
                      ? item.logo
                      : 'https://vod.aila.site/image/default/FE170CC66F2540498E6006698E866349-6-2.png'
                  "
                  fit="contain"
                ></el-image>

                <div class="otherMsg">
                  <p
                    class="name"
                    style="cursor: pointer"
                    @click="gotoCompany(item.company_id)"
                  >
                    {{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}
                  </p>
                  <p class="industry">{{ item.industry | industryFormat("zh") }}</p>
                </div>
              </div>

              <div class="interviewer">
                <img :src="item.avatar ? item.avatar : defaultAvatar" alt="" srcset="" />
                <div class="msg">
                  <p class="name">
                    {{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}
                  </p>
                  <p class="titie">
                    {{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}
                  </p>
                </div>
              </div>
              <div class="btns">
                <joinInterview
                  :channel_id="item.live_id"
                  :chat_type="item.chat_type"
                  :start_time="item.start_time"
                  :end_time="item.end_time"
                  style="margin-right: 15px"
                ></joinInterview>
                <cencenlInterview
                  :chat_type="item.chat_type"
                  :position_id="item.position_apply_id"
                  :one2oneChatId="item.chat_id"
                  @successChange="successChange"
                ></cencenlInterview>
            
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div> -->
    <div class="recommendBox content_area">
      <div class="recommendTitle">
        <p class="titleFl">职位推荐</p>
        <p class="titleFr" @click="gotojobList">
          更多专属职位<i class="el-icon-arrow-right"></i>
        </p>
      </div>
      <div class="recommendNav">
        <el-radio-group v-model="recommendRadio" @change="radioChange">
          <el-radio-button label="0">热招职位</el-radio-button>
          <el-radio-button label="1">最新职位</el-radio-button>
        </el-radio-group>
      </div>
      <div class="recommendList" v-loading="recommendLoading">
        <div
          style="width: 100%; height: 400px; position: relative"
          v-if="!recommendList.length"
        >
          <noDataImg></noDataImg>
        </div>
        <recommendJob
          class="recommendItem"
          :class="{ noMarginRight: (index + 1) % 3 == 0 }"
          v-for="(item, index) in recommendList"
          :item="item"
          :key="index"
        ></recommendJob>
      </div>
    </div>
    <!-- 日历选择 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="355px"
      class="selectTime"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
    >
      <datePicker
        @dateSelect="getdate"
        :default="defaultTime"
        :start="startTime"
        :end="endTime"
      ></datePicker>
    </el-dialog>
  </div>
</template>

<script>
import { getCityListByCountryId } from "~/baseUtils/AddressConfig";
import datePicker from "~/baseComponents/datePicker";
import noDataImg from "~/baseComponents/noDataImg";
import jobCanAppointmentBox from "~/projects/hebeiSociety/components/index/jobCanAppointment";
import joinInterview from "~scr/components/button/joinInterview";
import cencenlInterview from "~scr/components/button/cencenlInterview";
import negotiate from "~/projects/hebeiSociety/components/index/negotiate";
import recommendJob from "~/projects/hebeiSociety/components/index/recommendJob";
var interval;
export default {
  metaInfo: {
    title: "首页",
  },

  components: {
    jobCanAppointmentBox,
    negotiate,
    datePicker,
    recommendJob,
    noDataImg,
    cencenlInterview,
    joinInterview,
  },

  data() {
    return {
      companyNo: 0,
      Invitatloading: false,
      livingList: [],
      jobNo: 0,
      personNo: 0,
      select: "",
      keyWord: "",
      cityList: [],
      searchJob: "",
      dialogVisible: false,
      startTime: "",
      endTime: 9604073599,
      defaultTime: "",
      recommendRadio: 0,
      interviewerList: [],
      start: 1,
      limit: 6,
      total: 0,
      interviewList: [],
      interviewDate: "",
      hotSearchList: ["人事", "产品经理", "操作员", "海运销售"],
      recommendList: [],
      recommendLoading: false,
      chat_type: "",
      show: true,
    };
  },
  methods: {
    successChange() {
      this.getinterviewDataList();
    },
    gotoCompany(id) {
      this.$router.push({
        path: "/companyList/booth",
        query: {
          parameter: this._encode({
            company_id: id,
          }),
        },
      });
    },
    gotoTitleDetail(id) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: id,
          }),
        },
      });
    },
    gotojobList() {
      this.$router.push("/talents/recruitment");
    },
    handleSeachClick(value) {
      let params = {
        city: this.select,
        content: value || this.keyWord,
      };
      this.$router.push({
        path: "/talents/recruitment",
        query: params,
      });
    },
    getdate(result) {
      this.dialogVisible = false;
      let obj = {};
      this.interviewDate = this.$moment(result.time * 1000).format("YYYY-MM-DD");
      // this.getinterviewDataList();
    },
    async getTotalMsg() {
      let result = await this.$store.dispatch("API_index/getTotalMsg", {
        source: this.PJSource,
      });
      if (result.success) {
        this.companyNo = result.data.company_count;
        this.jobNo = result.data.job_position_count;
        this.personNo = result.data.participate_count;
      }
    },
    // 面试邀约列表
    async getinterviewDataList() {
      if (!this.USER_ID) {
        return;
      }
      this.Invitatloading = true;
      let parmas = {
        nopage: 1,
        user_id: this.USER_ID,
        start_time: this.$moment(this.interviewDate).unix(),
        end_time: this.$moment(this.interviewDate).unix() + 86399,
        now_time: this.$moment().unix(),
      };
      if (this.MEETING_INFO.end_time >= this.$moment(this.interviewDate).unix() + 86399) {
        parmas.chat_type = 1;
        this.chat_type = 1;
      } else {
        parmas.chat_type = 2;
        this.chat_type = 2;
      }
      let data = await this.$store.dispatch("API_index/getinterviewDataList", parmas);
      if (data.success) {
        let nowTime = this.$moment().unix();
        data.data.forEach((ee, vv) => {
          
          if (nowTime < ee.start_time - 900) {
            ee.state = 1;
          }
          if (nowTime > ee.start_time - 900 && nowTime < ee.start_time) {
            ee.state = 2;
          }
          if (nowTime > ee.start_time && nowTime < ee.end_time) {
            ee.state = 3;
          }
          if (nowTime > ee.end_time) {
            ee.state = 4;
          }
        });
        this.interviewList = data.data;
        this.Invitatloading = false;
      }
    },
 
    radioChange(e) {
    
      this.getJobRecommend();
    },
    // 获取职位推荐
    async getJobRecommend() {
      this.recommendLoading = true;
      let parmas = {
        start: 0,
        limit: 6,
        source:this.PJSource
      };
      if (this.recommendRadio == 0) {
        parmas.is_hot = 1;
      }
      let data = await this.$store.dispatch("API_index/getJobRecommend", parmas);
      if (data.success) {
        this.recommendLoading = false;
        this.recommendList = data.data;
      }
    },
    interviewerListPageChage(e) {
    
      this.start = e;
    },
    searchInterviewer(e) {
      this.start = 1;
    },
  },
  mounted() {
    (this.startTime = this.$moment().unix()), 
    this.interviewDate = this.$moment().format("YYYY-MM-DD");
    this.getTotalMsg();

    if (this.MEETING_STATUS == 1) {
      this.defaultTime = this.MEETING_INFO.start_time;
      this.interviewDate = this.$moment(this.MEETING_INFO.start_time * 1000).format(
        "YYYY-MM-DD"
      );
    } else {
      this.defaultTime = this.$moment().unix();
      this.interviewDate = this.$moment().format("YYYY-MM-DD");
    }
    // this.getinterviewDataList();
    this.getJobRecommend();
    this.cityList = getCityListByCountryId(1, "zh");
 
    this.show = this.USER_ID ? true : false;
  },
  updated() {
    clearInterval(interval);
  },
};
</script>
<style scoped lang="less">
.moduleTitle {
  .titleTxt {
    font-size: 24px;
    color: #000;
    font-weight: 600;
  }
  .titleDesc {
    font-size: 14px;
    color: #686868;
    margin: 10px 0;
  }
}
/deep/ .searchBox .input-with-select .el-select .el-input {
  width: 135px;
}
/deep/ .searchBox .el-select {
  width: 135px !important;
}
/deep/ .searchBox .el-button {
  background: #1890ff;
  color: #fff;
}
/deep/ .searchInput .el-button {
  background: #1890ff;
  color: #fff;
}
.liveBox {
  margin: 30px auto;
}
.dataAll {
  background: #fff;
  padding: 24px 0;
  margin: 30px auto;
  .dataList {
    width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dataItem {
      text-align: center;
      .title {
        font-size: 16px;
        color: #9b9b9b;
      }
      .number {
        font-size: 36px;
        color: #1890ff;
        font-weight: 600;
      }
    }
  }
  .searchBox {
    width: 800px;
    margin: 25px auto;
  }
  .keyWord {
    width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .keyWordTitle {
      font-size: 14px;
      color: #333;
    }
    .keyWordList {
      display: flex;
      span {
        color: #1890ff;
        padding: 0 7px;
        font-size: 13px;
        cursor: pointer;
      }
    }
  }
}
.bannerAd {
  margin: 30px auto;
  .images {
    width: 100%;
    height: 120px;
  }
}

.searchContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .searchInput {
    width: 500px;
  }
  .keyWordSearch {
    width: 700px;
    display: flex;
    flex-wrap: wrap;
    .keyWordItem {
      margin-right: 20px;
      margin-bottom: 10px;
      margin-top: 10px;
      font-size: 14px;
      display: block;
      padding: 5px 15px;
      background: #1890ff;
      color: #fff;
      border-radius: 15px;
    }
  }
}
.jobList {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  .jobItem {
    margin-bottom: 20px;
    margin-right: 14px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.InvitationBox {
  padding: 40px 0;
  background: #fff;
}
.Invitation {
  .timeBox {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: 900;
    font-size: 20px;
    align-items: center;
    .nowTime {
      width: 432px;
      height: 68px;
      text-align: center;
      line-height: 68px;
      border-top: 4px solid #1890ff;
      background: #fbfcfe;
      box-shadow: 1px -2px 6px 0px rgba(0, 0, 0, 0.19);
    }
    .timeIcon {
      img {
        width: 47px;
      }
    }
  }
  .InvitationList {
    padding: 40px 40px 10px 40px;
    max-height: 900px;
    overflow: auto;
    background: #fff;
    box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.11);
  }
  .InvitationItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 20px 0;
    .jobTime {
      width: 180px;
      font-weight: 500;
      font-size: 16px;
    }
    .compamyMsg {
      margin-top: 12px;
      display: flex;
      align-items: center;
      .logo {
        width: 40px;
        height: 40px;
        border: 1px solid #e9e9e9;
      }
      .otherMsg {
        width: 330px;
        margin-left: 15px;
        .name {
          padding-bottom: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }
        .industry {
          color: #999999;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .threeBox {
      width: 230px;
      text-align: center;
      font-size: 14px;

      .title {
        color: #333;
      }
      .money {
        color: #ff2b0d;
      }
      .mast {
        color: #333;
      }
    }

    .interviewer {
      display: flex;
      align-items: center;
      img {
        width: 57px;
        height: 57px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .msg {
        width: 120px;
        .name {
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-bottom: 10px;
          font-size: 16px;
        }
        .titie {
          color: #999;
          font-size: 14px;
          .online {
            color: #12bf48;
          }
        }
      }
    }
  }
}
.recommendBox {
  padding: 50px 0;
}
.recommendTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleFl {
    font-size: 24px;
    color: #000;
    font-weight: 600;
  }
  .titleFr {
    font-size: 14px;
    color: #666666;
    cursor: pointer;
  }
}
.recommendNav {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.recommendList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .recommendItem {
    margin: 0 13px 16px 0;
  }
  .noMarginRight {
    margin-right: 0;
  }
}
</style>
