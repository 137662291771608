<template>
  <div class="talk">
    <el-button type="primary" :size="size" round @click="toHerf" v-if="!type"
      >立即沟通</el-button
    >
    <span @click="toHerf" v-else><slot name="btn"></slot></span>
  </div>
</template>
<script>
import { getAilaDomainName } from "~/baseUtils";

export default {
  props: {
    channel_id: {
      type: String | Number,
      default: "",
    },
    size: {
      type: String,
      default: "mini",
    },
    status: {
      //当前在线状态
      type: String | Number,
      default: "",
    },
    userId: {
      //职位负责人id，对需要验证当前负责人是否在线的需求进修的修改
      type: String | Number,
      default: "",
    },
    oToId: {
      //1对1 ID，对需要验证当前负责人是否在线的需求进修的修改
      type: String | Number,
      default: "",
    },
  },
  data() {
    return {
      type: false,
    };
  },
  methods: {
    async toHerf() {
      if (this.IS_LOGIN) {
        let status = null;
        if (this.status) {
          status = this.status;
        } else {
          let params = {
            meeting_id: this.MEETING_ID,
            user_id: this.userId,
            one2one_id: this.oToId,
          };
          let data = await this.$store.dispatch(
            "API_position/getIsOnline",
            params
          );
          if (data.success) {
            status = data.data;
          }
        }
        if (status == 1) {
          let path = getAilaDomainName("zh");
          let params = this._encode({
            channel_id: this.channel_id,
            user_id: this.USER_INFO.id,
            type: "free",
          });
          let herfPath = `${path}/networkTalking/biunique?parameter=${params}`;
          window.open(herfPath);
        } else if (status == 2) {
          this.$message.warning("当前用户正在忙碌中");
        } else if (status == 3) {
          this.$message.warning("用户已离线");
        }
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }
    },
  },
  mounted() {
    this.type = this.$scopedSlots.$hasNormal;
  },
};
</script>
<style lang="less" scoped>
.talk {
  display: inline-block;
}
</style>
