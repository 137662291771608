<template>
  <div class="cencenlInterview">
    <el-button
      round
      @click="cencenlResume"
      :size="size"
      v-if="!type"
      :loading="btnLoading"
      >取消面试</el-button
    >
    <span @click="cencenlResume" v-else><slot name="btn"></slot></span>
  </div>
</template>
<script>
export default {
  props: {
    chat_type: {
      type: Number | String,
      default: null,
    },
    position_id: {
      type: Number | String,
      default: null,
    },
    one2oneChatId: {
      //邀约id
      type: Number | String,
      default: null,
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  data() {
    return {
      type: false,
      btnLoading: false,
    };
  },
  mounted() {
    this.type = this.$scopedSlots.$hasNormal;
  },
  methods: {
    // 取消面试
    async cencenlResume() {
      if (this.CHECK_LOGIN) {
        if (!this.type) {
          this.btnLoading = true;
        } else {
          this.$emit("loadingShow", true);
        }
        let params = {
          meeting_type: this.chat_type,
          status: 4,
          position_apply_id: this.position_id,
          one2one_chat_id: this.one2oneChatId,
          position_apply_status: 7, //求职者取消面试
        };
        if (this.MEETING_STATUS != 3) params.meeting_id = this.MEETING_ID;
        let res = await this.$store.dispatch(
          "API_position/cencelApply",
          params
        );
        if (res.success) {
          this.$message.success(this.$t("Successful_operation"));
          this.$emit("successChange");
        } else {
          this.$seTip();
        }
        if (!this.type) {
          this.btnLoading = false;
        } else {
          this.$emit("loadingShow", false);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.cencenlInterview {
  display: inline-block;
}
</style>